import React from "react";
import { Link } from "react-router-dom";
import directLineLogo from "../assets/direct-line-logo.png";

const Header = () => {
  return (
    <div className="flex-center header">
      <Link to="/" className="text-decoration-none">
        <img src={directLineLogo} height={190} width="auto"></img>
      </Link>
    </div>
  );
};

export default Header;
