import React from "react";
import bbcLogo from "../assets/bbc-white.png";

const Footer = () => {
  return (
    <div className="flex-center footer">
      <img src={bbcLogo} height={100} width="auto" />
    </div>
  );
};

export default Footer;
