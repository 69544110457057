import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Leaderboard from "../components/Leaderboard";
import { useLocation } from "react-router-dom";

const Score = () => {
  const location = useLocation();
  const { score } = location.state || { score: 0 }; // Retrieve the score from state

  const [isHighScore, setIsHighScore] = useState(false); // Track if the user has a high score
  const [name, setName] = useState(""); // Track user's name
  const [hasSubmitted, setHasSubmitted] = useState(false); // Track if the user has submitted their name
  const [resultMessage, setResultMessage] = useState({
    headerMessage: "Your Score",
    bodyMessage: "",
  }); // Track the result message
  const [leaderboardVisible, setLeaderboardVisible] = useState(false); // Control leaderboard visibility
  const [loading, setLoading] = useState(true); // Track the loading state
  const [errorMessage, setErrorMessage] = useState(""); // Track the error message

  // Send the score to the API on mount
  useEffect(() => {
    const checkHighScore = async () => {
      try {
        // Send initial POST request with the score
        const formData = new FormData();
        formData.append("score", score);

        const response = await fetch(
          "https://quiz.bbcdirectline.com/api/score_check",
          {
            method: "POST",
            body: formData,
          }
        );

        const data = await response.json();

        // If the response has `is_highscore: true`, prompt the user for their name
        if (data.is_highscore) {
          setIsHighScore(true);
          setResultMessage({
            headerMessage: "High Score",
            bodyMessage:
              "Congrats! You achieved a high score. Enter your name:",
          });
        } else {
          setResultMessage({
            headerMessage: "Your Score",
            bodyMessage:
              "You didn't place this time, but you can always try again!",
          });
          setLeaderboardVisible(true); // Show leaderboard if not a high score
        }
      } catch (error) {
        console.error("Error checking score:", error);
        setResultMessage({
          headerMessage: "Error",
          bodyMessage: "An error occurred. Please try again later.",
        });
      }
      setLoading(false); // Stop loading after API response
    };

    checkHighScore();
  }, [score]);

  // Handle form submission for high score
  const handleSubmit = async () => {
    if (hasSubmitted) return;

    if (!name.trim()) {
      // Check if name is empty or only contains spaces
      setErrorMessage("Name cannot be empty.");
      return;
    }

    setHasSubmitted(true); // Hide the input field after submission
    setErrorMessage(""); // Clear any previous error messages

    try {
      const formData = new FormData();
      formData.append("score", score);
      formData.append("name", name);

      const response = await fetch("https://quiz.bbcdirectline.com/api/score", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      setResultMessage({
        headerMessage: "High Score",
        bodyMessage: "Your high score has been saved!",
      });
      setLeaderboardVisible(true); // Show leaderboard after saving the high score
    } catch (error) {
      console.error("Error saving high score:", error);
      setHasSubmitted(false); // Hide the input field after submission
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  if (loading) {
    return <div className="flex-center h-100">Finalising score...</div>; // Display loading spinner or message
  }

  return (
    <div className="score h-100 d-flex flex-column align-items-center">
      <div className="scoreBox flex-center flex-column">
        <div className="scoreHeader">{resultMessage.headerMessage}</div>
        <div className="scoreValue">{score}</div>
        <div className="scoreMessage text-center">
          {resultMessage.bodyMessage}
        </div>
      </div>

      {isHighScore &&
        !hasSubmitted && ( // Only show if high score and hasn't been submitted
          <div className="highScoreForm text-center">
            <input
              className="nameInput mb-4"
              type="text"
              placeholder="Insert name..."
              value={name}
              onChange={(e) => setName(e.target.value)}
              onKeyDown={handleKeyDown} // Call handleSubmit on "Enter"
            />
            <a
              className="submit-btn text-decoration-none"
              onClick={handleSubmit}
            >
              SUBMIT
            </a>
            <div className="error-message mt-4">{errorMessage}</div>
          </div>
        )}

      {leaderboardVisible && <Leaderboard />}
      {leaderboardVisible && (
        <Link to="/" className="submit-btn text-decoration-none mt-5">
          TRY AGAIN
        </Link>
      )}
    </div>
  );
};

export default Score;
