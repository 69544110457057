import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./styles/main.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Idle from "./pages/Idle";
import Quiz from "./pages/Quiz";
import Score from "./pages/Score";
import Header from "./components/Header";
import Footer from "./components/Footer";

export default function App() {
  return (
    <div className="full-page d-flex justify-content-center w-100">
      <div className="body-wrapper flex-center flex-column">
        <BrowserRouter>
          <Header />
          <Routes>
            <Route path="*" element={<Idle />} />
            <Route path="quiz" element={<Quiz />} />
            <Route path="score" element={<Score />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </div>
  );
}
