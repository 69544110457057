import React, { useState, useEffect } from "react";

const Leaderboard = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://quiz.bbcdirectline.com/api/score/`
        );
        if (!response.ok) {
          throw new Error(`HTTP error status: ${response.status}`);
        }
        const data = await response.json();
        setLeaderboard(data.scores); // Assuming data has a "scores" field
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Stop loading if there's an error
      }
    };
    fetchData();
  }, []);

  return (
    <div className="leaderboard flex-center flex-column">
      {/* Show skeletons while loading */}
      {loading ? (
        <>
          <div className="leaderboard-item p-2">Loading...</div>
          <div className="leaderboard-item mt-5 p-2">Loading...</div>
          <div className="leaderboard-item mt-5 p-2">Loading...</div>
        </>
      ) : (
        leaderboard.slice(0, 3).map((player, idx) => (
          <div
            key={idx}
            className={`leaderboard-item ${idx !== 0 && "mt-5"} p-2`}
          >
            <div className="leaderboardNameWrapper">
              <span>{idx + 1}.&nbsp;</span>
              <span className="leaderboardName text-start">{player.name}</span>
            </div>
            <span>{player.score}</span>
          </div>
        ))
      )}
    </div>
  );
};

export default Leaderboard;
