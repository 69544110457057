import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Quiz = () => {
  const navigate = useNavigate();

  const [timeLeft, setTimeLeft] = useState(100); // 100% width (full bar)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Track the current question
  const [questionData, setQuestionData] = useState([]);
  const [timerExpired, setTimerExpired] = useState(false); // New state to track if the timer expired
  const [loading, setLoading] = useState(true); // Add loading state
  const [score, setScore] = useState(0); // Score state
  const [incorrectIndices, setIncorrectIndices] = useState([]); // Track multiple incorrect answers

  const duration = 14; // Total duration in seconds for the timer
  const baseScore = 2000; // Base score for each question
  const penalty = 200; // Penalty score for incorrect answers

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://quiz.bbcdirectline.com/api/questions/`
        );
        if (!response.ok) {
          throw new Error(`HTTP error status: ${response.status}`);
        }
        const data = await response.json();
        setQuestionData(data.questions); // Assuming data has a "questions" field
        setLoading(false); // Set loading to false when data is fully loaded
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // Function to move to the next question
  const goToNextQuestion = () => {
    setIncorrectIndices([]); // Clear incorrect indices when moving to the next question
    if (currentQuestionIndex < questionData?.length - 1) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setTimeLeft(100); // Reset timer
      setTimerExpired(false); // Reset timerExpired for the next question
    } else {
      // Handle the end of the quiz (e.g., show a message or reset the quiz)
      navigate("/score", { state: { score } });
    }
  };

  // Countdown timer logic
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev > 0) return prev - 100 / duration;
        clearInterval(interval);
        setTimerExpired(true); // Mark timer as expired
        return 0;
      });
    }, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(interval);
  }, [duration, currentQuestionIndex]); // Add currentQuestionIndex as dependency to reset timer for each new question

  // Automatically go to the next question when the timer runs out
  useEffect(() => {
    if (timerExpired) {
      goToNextQuestion();
    }
  }, [timerExpired]); // Trigger goToNextQuestion when the timerExpired state is true

  // Function to handle answer selection
  const handleAnswerClick = (index) => {
    // Check if the selected answer is correct
    if (questionData[currentQuestionIndex].choices[index].is_correct) {
      const timeBonus = Math.floor((timeLeft / 100) * baseScore); // Higher score for more time left
      setScore((prevScore) => prevScore + timeBonus);
      goToNextQuestion();
    } else {
      setIncorrectIndices((prev) => [...prev, index]); // Add the incorrect answer to the array

      // Apply penalty and ensure score doesn't go below zero
      setScore((prevScore) => Math.max(prevScore - penalty, 0));

      // Optionally: clear the effect after some time
      setTimeout(() => {
        setIncorrectIndices((prev) => prev.filter((i) => i !== index)); // Optionally remove after a delay
      }, 1000); // Delay of 1 second
    }
  };

  if (loading) {
    return <div className="flex-center h-100">Loading questions...</div>; // Display loading spinner or message
  }

  return (
    <div className="quiz h-100 d-flex flex-column pb-4 align-items-center flex-1 mt-5 w-100">
      {/* Timer bar */}
      <div className="timer-bar w-100 mb-3">
        <div
          className="timer-fill"
          style={{
            width: `${timeLeft}%`,
          }}
        ></div>
      </div>
      {/* Current Question */}
      <div className="quiz-question w-100 text-center container flex-center">
        {questionData.length > 0 && (
          <img
            src={`https://d1gebqhwyea686.cloudfront.net/questions-${questionData[currentQuestionIndex].id_question}.png`}
            alt={`Question ${questionData[currentQuestionIndex].id_question}`}
            className="w-100"
          />
        )}
      </div>

      {/* Quiz Options */}
      <div className="quiz-options w-100 container d-flex flex-column flex-grow-1 m-3">
        <Row className="flex-grow-1">
          {questionData[currentQuestionIndex].choices.map((choice, idx) => (
            <Col
              key={idx}
              className={`col-6 quiz-option ${
                incorrectIndices.includes(idx) ? "incorrect-answer" : ""
              }`}
              onClick={() => handleAnswerClick(idx)}
            >
              <img
                src={`https://d1gebqhwyea686.cloudfront.net/choices-${choice.id_choice}.png`}
                height={380}
                alt={`Answer ${idx + 1}`}
                width="auto"
              />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default Quiz;
