import React from "react";
import Leaderboard from "../components/Leaderboard";
import { Link } from "react-router-dom";

const Idle = () => {
  return (
    <div className="idle d-flex container flex-column py-4 align-items-center flex-1 h-100">
      <div className="instructionsHeader">How to play</div>
      <div className="instructions text-center">
        Answer each question before the time runs out.
        <br />
        Faster answers earn more points.
        <br />
        Wrong answers will lower your score.
        <br />
        There are 5 questions in total.
      </div>
      <div className="leaderboardHeader mt-5">Leaderboard</div>
      <Leaderboard />
      <Link to="/quiz" className="start-btn text-decoration-none">
        START
      </Link>
    </div>
  );
};

export default Idle;
